import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { first, map, Observable, share } from "rxjs";
import { UserInfoDto, UserScope } from "src/app/common/dto/user-info";
import { FeatureFlag } from "../enums/feature-flag";
import { AuthService } from "./auth.service";
import { CookieService } from "./cookie.service";
import { FeatureFlagService } from "./feature-flag.service";

export type PortalChoice = "kp" | "png";

export const PNG_URL = "/png-management/";

interface DualRoleParams {
  hasKommunalplattformAccess: boolean;
  hasPngAccess: boolean;
  userScope: UserScope;
}

/**
 * This service's aim is to check whether a user has access to the
 * Kommunalplattform (KP) or the KomPacht (PNG) area or both. If an _internal_*
 * user has access to both areas, the user should be redirected to KomPacht
 * automatically. All other users should be redirected to the portal page where
 * they can choose where to go.
 *
 * *) an "internal user" in this case is a user from NetzeBW. Coming from
 * keycloak, the `userScope` will be `externalStakeholder`.
 *
 * The following table shows the possible user types and their access rights:
 *
 * > | user type           | access   | go to    |
 * > |---------------------|----------|----------|
 * > | any                 | only KP  | KP       | test_nex-8832_onlykp@example.com
 * > | any                 | only PNG | KomPacht | test_nex-8832_onlypng@example.com
 * > | externalStakeholder | KP & PNG | KomPacht | test_nex-8832_ext_kp&png@example.com
 * > | internalStakeholder | KP & PNG | Portal   | test_nex-8832_int_kp&png@example.com
 * > | endUser             | KP & PNG | Portal   | test_nex-8832_enduser_kp&png@example.com
 *
 */
@Injectable({ providedIn: "root" })
export class DualRolesService {
  private params$: Observable<DualRoleParams>;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private featureFlag: FeatureFlagService,
    private authService: AuthService
  ) {}
  public init(): void {
    this.params$ = this.authService.getUserInfo().pipe(
      first(),
      map((userInfo) => ({ ...this.checkAccess(userInfo), userScope: userInfo.userScope } satisfies DualRoleParams)),
      share()
    );
    this.params$.subscribe((params) => {
      this.maybeSwitchToPortal(params);
    });
  }

  public maybeSwitchToPortal(params: DualRoleParams): void {
    if (!this.featureFlag.isEnabled(FeatureFlag.DualRolesPortal)) {
      return;
    }
    if (window.location.pathname !== "/" || !params.hasPngAccess) {
      // Deep-link or refresh, do nothing, just stay on the page.
      // Also, if user doesn't have PNG access, do nothing
      return;
    }

    const isInternalUser = params.userScope === UserScope.EXTERNAL_STAKEHOLDER;

    if (isInternalUser) {
      // internal users should always be redirected to PNG
      this.redirectToPng();
      return;
    } else {
      if (params.hasKommunalplattformAccess) {
        // dual role user. Redirect to the /portal page where the user can choose
        this.redirectToRememberedChoiceOrPortal();
        return;
      } else {
        // only png access, so redirect to PNG
        this.redirectToPng();
        return;
      }
    }
  }

  public redirectToRememberedChoiceOrPortal(): void {
    const choice = this.getPortalChoiceCookie();
    switch (choice) {
      case "kp":
        this.redirectToKp();
        break;
      case "png":
        this.redirectToPng();
        break;
      default:
        this.redirectToPortal();
        break;
    }
  }

  public redirectToPng(): void {
    window.location.href = PNG_URL;
  }

  public redirectToKp(): void {
    this.router.navigate(["/"]);
  }

  public redirectToPortal(): void {
    this.router.navigate(["/portal"]);
  }

  public setPortalChoiceCookie(choice: PortalChoice): void {
    this.cookieService.set("dualRolesPortalChoice", choice);
  }

  public getPortalChoiceCookie(): PortalChoice | null {
    return this.cookieService.get("dualRolesPortalChoice") as "kp" | "png";
  }

  public getParams(): Observable<DualRoleParams> {
    return this.params$;
  }

  private checkAccess(userInfo: UserInfoDto): { hasKommunalplattformAccess: boolean; hasPngAccess: boolean } {
    let hasKommunalplattformAccess = false;
    let hasPngAccess = false;
    for (const [key, value] of Object.entries(userInfo?.permissions?.scoped || {})) {
      if (key.startsWith("debitor:")) {
        if (value.some((role) => role.startsWith("module-00018."))) {
          hasPngAccess = true;
        }
      } else {
        // if it is not a PNG permission, it must be a KP permission
        hasKommunalplattformAccess = true;
      }

      if (hasPngAccess && hasKommunalplattformAccess) {
        break;
      }
    }
    return {
      hasPngAccess,
      hasKommunalplattformAccess
    };
  }
}
