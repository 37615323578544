import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppConfig } from "../helpers/app.config";

@Injectable({ providedIn: "root" })
export class JsonsService {
  private readonly http = inject(HttpClient);

  getPlugins(): Observable<Array<Record<string, any>>> {
    return this.http.get<{ result: Array<Record<string, string>> }>(AppConfig.connection.json.plugins).pipe(
      map(({ result }) => result),
      catchError(() => of([]))
    );
  }

  getRegulatedGlossaries(): Observable<any> {
    return this.http.get("/assets/mock/glossary.json").pipe(catchError(() => of({})));
  }

  getUpdateCycle(): Observable<Array<{ parentId: string; childId: number; category: string; cycle: string }>> {
    return this.http
      .get<{ result: Array<{ parentId: string; childId: number; category: string; cycle: string }> }>(
        "/assets/jsons/update-cycle.json"
      )
      .pipe(
        map(({ result }) => result),
        catchError(() => of([]))
      );
  }
}
